
import { useState, useEffect } from "react";
import API from "../../../utils/API";

function useFetchStaffData(url) {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        API.get(url)
            .then(res => {
                if (res.data) {
                    setData(res.data)
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
        // eslint-disable-next-line 
    }, [url]);

    return { data, loading };
}


export default useFetchStaffData;