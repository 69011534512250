import { Table } from 'antd';
import React from 'react'
import useFetchStaffData from './useFetchStaffData';

const SalesByInvoiceServicesDetail = (props) => {
    const url = `/api/history/get-sales-by-invoice/services-detail?invoiceId=${props.invoiceId}`
    let { data, loading } = useFetchStaffData(url);
    const columns = [
        {
            title: <span style={{ fontSize: "0.8em", fontWeight: "bold" }}>Service</span>,
            dataIndex: 'name',
            key: 'name',
            width: 140,
            render: name => <span style={{ fontSize: "0.8em" }}>{name}</span>
        },

        {
            title: <span style={{ fontSize: "0.8em", fontWeight: "bold" }}>Price</span>,
            dataIndex: 'price',
            key: 'price',
            render: (price) => <span style={{ fontSize: "0.8em" }}>{price.toFixed(2)}</span>

        },
        {
            title: <span style={{ fontSize: "0.8em", fontWeight: "bold" }}>Discounts</span>,
            dataIndex: "discounts",
            key: "discounts",
            render: (discounts, record) => {
                if (!discounts) return <span style={{ fontSize: "0.8em" }}>0</span>;
                const totalDiscount = discounts.reduce((acc, discount) => {
                    if (discount.type === 'amount') {
                        return acc + discount.value;
                    } else if (discount.type === 'percentage') {
                        return acc + (record.price * (discount.value / 100));
                    }
                    return acc;
                }, 0);
                return <span style={{ fontSize: "0.8em" }}>-{totalDiscount.toFixed(2)}</span>; // Format to 2 decimal places
            }
        },
        {
            title: <span style={{ fontSize: "0.8em", fontWeight: "bold" }}>Product</span>,
            dataIndex: 'serviceId',
            key: 'serviceId',
            render: (serviceId) => <span style={{ fontSize: "0.8em" }}>{serviceId ? -serviceId.productCost.toFixed(2) : 0}</span>

        },
    ];

    return (
        <Table
            bordered={true}
            rowKey="_id"
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={loading}
            size="small"
        />
    )
}

export default SalesByInvoiceServicesDetail