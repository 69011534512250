import React, { useEffect } from 'react'
import HistoryDateSelect from '../../../component/History/HistoryDateSelect'
import { useDispatch, useSelector } from 'react-redux'
import { MessageError } from '../../../component/Common/MessageError/MessageError'
import { getHistorySales } from '../../../redux/actions/history/history'
import { formatRangeDate } from '../../../utils/utility'
import SalesByDate from './salesByDate'
import { Table } from 'antd'
import { Popover, Tabs } from 'antd-mobile'
import SalesByInvoice from './SalesByInvoice'

const Sales = (props) => {
    const dispatch = useDispatch()
    const { sales, message, error, loading } = useSelector(state => state.history)
    const { business, staff } = useSelector(state => state.generalInfo)
    const { from, to } = props.historyFromTo

    const columns = [
        {
            title: 'Service',
            dataIndex: '_id',
            key: '_id',
            width: 140
        },
        {
            title: 'Quan.',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Sub.',
            dataIndex: 'grossSales',
            key: 'grossSales',
            sorter: (a, b) => (a.grossSales > b.grossSales ? 1 : -1),
            defaultSortOrder: 'descend',
            render: (value) => (Number(value).toFixed(2))
        },
        {
            title: <div><Popover
                content='Discounts + Product cost'
                placement='bottom-start'
                mode='dark'
                trigger='click'
            >
                <div>Deduct.</div>
            </Popover></div>,
            dataIndex: 'deduction',
            key: 'deduction',
            render: (value) => (Number(value).toFixed(2))
        },
        {
            title: 'Net',
            dataIndex: 'serviceCommission',
            key: 'serviceCommission',
            render: (value) => (Number(value).toFixed(2))
        },
    ];

    useEffect(() => {
        const { fromWithHour, toWithHour } = formatRangeDate(from, to, business?.timeZone)
        dispatch(getHistorySales(fromWithHour, toWithHour))
        // eslint-disable-next-line
    }, [from, to])

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])


    // if (loading) return <Loading loading={loading} />


    return (
        <div style={{ padding: "20px 0px", width: "100%" }}>

            <HistoryDateSelect historyFromTo={props.historyFromTo} />
            {staff?.serviceCommission &&
                <div style={{ margin: "10px 0px" }}>
                    <h3 style={{ margin: "0px" }}>Service Commission: {staff.serviceCommission}%</h3>
                </div>
            }
            <Tabs defaultActiveKey='1'>
                <Tabs.Tab title='By Services' key='1'>
                    <Table
                        // key={Math.random()}
                        loading={loading}
                        size='small'
                        rowKey='_id'
                        dataSource={sales}
                        columns={columns}
                        // scroll={{ y: window.innerHeight - (staff?.serviceCommission ? 380 : 350) }}
                        pagination={false}
                        sticky={true}
                        style={{ margin: "10px 0px" }}
                        summary={(pageData) => {
                            let grossSales = 0
                            let deduction = 0
                            let serviceCommission = 0
                            let quantity = 0
                            pageData.forEach(item => {
                                grossSales += item.grossSales
                                quantity += item.quantity
                                deduction += item.deduction
                                serviceCommission += item.serviceCommission
                            })

                            if (pageData.length > 0) {
                                return (
                                    <Table.Summary fixed="top">
                                        <Table.Summary.Row style={{ fontWeight: "bold" }}>
                                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell>{Number(quantity)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{Number(grossSales).toFixed(2)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{Number(deduction).toFixed(2)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{Number(serviceCommission).toFixed(2)}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                )
                            }
                        }}
                    />
                </Tabs.Tab>
                <Tabs.Tab title='By Date' key='2'>
                    <SalesByDate historyFromTo={props.historyFromTo} />
                </Tabs.Tab>
                <Tabs.Tab title='By Invoice' key='3'>
                    <SalesByInvoice historyFromTo={props.historyFromTo} />
                </Tabs.Tab>
            </Tabs>



        </div>
    )
}

export default Sales