import {
    GET_HISTORY_SALES_STARTED,
    GET_HISTORY_SALES_SUCCESS,
    GET_HISTORY_SALES_FAILURE,
    GET_HISTORY_SALES_BY_DATE_STARTED,
    GET_HISTORY_SALES_BY_DATE_SUCCESS,
    GET_HISTORY_SALES_BY_DATE_FAILURE,
    GET_HISTORY_TIPS_STARTED,
    GET_HISTORY_TIPS_SUCCESS,
    GET_HISTORY_TIPS_FAILURE,
    GET_HISTORY_TIPS_BY_DATE_STARTED,
    GET_HISTORY_TIPS_BY_DATE_SUCCESS,
    GET_HISTORY_TIPS_BY_DATE_FAILURE,
    GET_HISTORY_SALES_BY_INVOICE_STARTED,
    GET_HISTORY_SALES_BY_INVOICE_SUCCESS,
    GET_HISTORY_SALES_BY_INVOICE_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/history/history";

const initialState = {
    tips: null,
    sales: null,
    salesByDate: null,
    salesByInvoice: null,
    tipsByDate: null,
    loading: false,
    error: null,
    message: null,
};

export default function history(state = initialState, action) {
    switch (action.type) {
        case GET_HISTORY_SALES_BY_INVOICE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_HISTORY_SALES_BY_INVOICE_SUCCESS:
            return {
                ...state,
                salesByInvoice: action.payload.sale,
                error: null,
                loading: false,
            };
        case GET_HISTORY_SALES_BY_INVOICE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_HISTORY_TIPS_BY_DATE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case GET_HISTORY_TIPS_BY_DATE_SUCCESS:
            return {
                ...state,
                tipsByDate: action.payload.tips,
                error: null,
                loading: false,
            };
        case GET_HISTORY_TIPS_BY_DATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case GET_HISTORY_SALES_BY_DATE_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_HISTORY_SALES_BY_DATE_SUCCESS:
            return {
                ...state,
                salesByDate: action.payload.sale,
                error: null,
                loading: false,
            };
        case GET_HISTORY_SALES_BY_DATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_SALES_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_HISTORY_SALES_SUCCESS:
            return {
                ...state,
                sales: action.payload.sale,
                error: null,
                loading: false,
            };

        case GET_HISTORY_SALES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_HISTORY_TIPS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_HISTORY_TIPS_SUCCESS:
            return {
                ...state,
                tips: action.payload.tips,
                error: null,
                loading: false,
            };

        case GET_HISTORY_TIPS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        default:
            return { ...state };
    }
}
