import {
    GET_HISTORY_SALES_STARTED,
    GET_HISTORY_SALES_SUCCESS,
    GET_HISTORY_SALES_FAILURE,
    GET_HISTORY_SALES_BY_DATE_STARTED,
    GET_HISTORY_SALES_BY_DATE_SUCCESS,
    GET_HISTORY_SALES_BY_DATE_FAILURE,
    GET_HISTORY_TIPS_STARTED,
    GET_HISTORY_TIPS_SUCCESS,
    GET_HISTORY_TIPS_FAILURE,
    GET_HISTORY_TIPS_BY_DATE_STARTED,
    GET_HISTORY_TIPS_BY_DATE_SUCCESS,
    GET_HISTORY_TIPS_BY_DATE_FAILURE,
    GET_HISTORY_SALES_BY_INVOICE_STARTED,
    GET_HISTORY_SALES_BY_INVOICE_SUCCESS,
    GET_HISTORY_SALES_BY_INVOICE_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/history/history";

import { LOGOUT } from "../../constants/authenticate/authenticate";

import API from "../../../utils/API";

export const getHistorySalesByInvoice = (from, to) => {
    return (dispatch) => {
        dispatch(getHistorySalesByInvoiceStarted());
        API.get(`/api/history/get-sales-by-invoice?from=${from}&to=${to}`)
            .then((res) => {
                dispatch(getHistorySalesByInvoiceSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getHistorySalesByInvoiceFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };

}

const getHistorySalesByInvoiceStarted = () => ({
    type: GET_HISTORY_SALES_BY_INVOICE_STARTED,
});

const getHistorySalesByInvoiceSuccess = (data) => ({
    type: GET_HISTORY_SALES_BY_INVOICE_SUCCESS,
    payload: {
        sale: data,
    },
});

const getHistorySalesByInvoiceFailure = (error) => ({
    type: GET_HISTORY_SALES_BY_INVOICE_FAILURE,
    payload: { error },
});


export const getHistoryTipsByDate = (from, to) => {
    return (dispatch) => {
        dispatch(getHistoryTipsByDateStarted());
        API.get(`/api/history/get-tips-by-date?from=${from}&to=${to}`)
            .then((res) => {
                dispatch(getHistoryTipsByDateSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getHistoryTipsByDateFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };

}

const getHistoryTipsByDateStarted = () => ({
    type: GET_HISTORY_TIPS_BY_DATE_STARTED,
})

const getHistoryTipsByDateSuccess = (data) => ({
    type: GET_HISTORY_TIPS_BY_DATE_SUCCESS,
    payload: {
        tips: data,
    },
})

const getHistoryTipsByDateFailure = (error) => ({
    type: GET_HISTORY_TIPS_BY_DATE_FAILURE,
    payload: { error },
})

export const getHistoryTips = (from, to) => {
    return (dispatch) => {
        dispatch(getHistoryTipsStarted());
        API.get(`/api/history/get-tips?from=${from}&to=${to}`)
            .then((res) => {
                dispatch(getHistoryTipsSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getHistoryTipsFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const getHistoryTipsStarted = () => ({
    type: GET_HISTORY_TIPS_STARTED,
});

const getHistoryTipsSuccess = (data) => ({
    type: GET_HISTORY_TIPS_SUCCESS,
    payload: {
        tips: data,
    },
});

const getHistoryTipsFailure = (error) => ({
    type: GET_HISTORY_TIPS_FAILURE,
    payload: { error },
});

export const getHistorySalesByDate = (from, to) => {
    return (dispatch) => {
        dispatch(getHistorySalesByDateStarted());
        API.get(`/api/history/get-sales-by-date?from=${from}&to=${to}`)
            .then((res) => {
                dispatch(getHistorySalesByDateSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getHistorySalesByDateFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };

}

const getHistorySalesByDateStarted = () => ({
    type: GET_HISTORY_SALES_BY_DATE_STARTED,
});

const getHistorySalesByDateSuccess = (data) => ({
    type: GET_HISTORY_SALES_BY_DATE_SUCCESS,
    payload: {
        sale: data,
    },
});

const getHistorySalesByDateFailure = (error) => ({
    type: GET_HISTORY_SALES_BY_DATE_FAILURE,
    payload: { error },
});



export const getHistorySales = (from, to) => {
    return (dispatch) => {
        dispatch(getHistorySalesStarted());
        API.get(`/api/history/get-sales?from=${from}&to=${to}`)
            .then((res) => {
                dispatch(getHistorySalesSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getHistorySalesFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const getHistorySalesStarted = () => ({
    type: GET_HISTORY_SALES_STARTED,
});

const getHistorySalesSuccess = (data) => ({
    type: GET_HISTORY_SALES_SUCCESS,
    payload: {
        sale: data,
    },
});

const getHistorySalesFailure = (error) => ({
    type: GET_HISTORY_SALES_FAILURE,
    payload: { error },
});

const logoutUser = () => ({
    type: LOGOUT
})


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message
    if (error.response) {
        message = error.response.data.message
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};