import React, { useEffect } from 'react'
// import HistoryDateSelect from '../../../component/History/HistoryDateSelect'
import { useDispatch, useSelector } from 'react-redux'

import { MessageError } from '../../../component/Common/MessageError/MessageError'
import { getHistorySalesByDate } from '../../../redux/actions/history/history'
import { formatRangeDate } from '../../../utils/utility'
import { Table } from 'antd'
import { Popover } from 'antd-mobile'
import dayjs from 'dayjs'
const SalesByDate = (props) => {

    const dispatch = useDispatch()
    const { salesByDate, message, error, loading } = useSelector(state => state.history)
    const { business } = useSelector(state => state.generalInfo)
    const { from, to } = props.historyFromTo

    const columns = [
        {
            title: 'Date',
            dataIndex: '_id',
            key: '_id',

            render: date => dayjs(date).format("MMM DD")
        },
        {
            title: 'Quan.',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Sub.',
            dataIndex: 'grossSales',
            key: 'grossSales',
            sorter: (a, b) => (a.grossSales > b.grossSales ? 1 : -1),
            defaultSortOrder: 'descend',
            render: (value) => (Number(value).toFixed(2))
        },
        {
            title: <div><Popover
                content='Discounts + Product cost'
                placement='bottom-start'
                mode='dark'
                trigger='click'
            >
                <div>Deduct.</div>
            </Popover></div>,
            dataIndex: 'deduction',
            key: 'deduction',
            render: (value) => (Number(value).toFixed(2))
        },
        {
            title: 'Net',
            dataIndex: 'serviceCommission',
            key: 'serviceCommission',
            render: (value) => (Number(value).toFixed(2))
        },
    ];

    useEffect(() => {
        const { fromWithHour, toWithHour } = formatRangeDate(from, to, business?.timeZone)
        dispatch(getHistorySalesByDate(fromWithHour, toWithHour))
        // eslint-disable-next-line
    }, [from, to])

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])


    // if (loading) return <Loading loading={loading} />


    return (

        <Table
            // key={Math.random()}
            loading={loading}
            size='small'
            rowKey='_id'
            dataSource={salesByDate}
            columns={columns}
            // scroll={{ y: window.innerHeight - (staff?.serviceCommission ? 380 : 350) }}
            pagination={false}
            sticky={true}
            style={{ margin: "10px 0px" }}
            summary={(pageData) => {
                let grossSales = 0
                let deduction = 0
                let serviceCommission = 0
                let quantity = 0
                pageData.forEach(item => {
                    grossSales += item.grossSales
                    quantity += item.quantity
                    deduction += item.deduction
                    serviceCommission += item.serviceCommission
                })

                if (pageData.length > 0) {
                    return (
                        <Table.Summary fixed="top" >
                            <Table.Summary.Row style={{ fontWeight: "bold" }}>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                <Table.Summary.Cell>{Number(quantity)}</Table.Summary.Cell>
                                <Table.Summary.Cell>{Number(grossSales).toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell>{Number(deduction).toFixed(2)}</Table.Summary.Cell>
                                <Table.Summary.Cell>{Number(serviceCommission).toFixed(2)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }
            }}
        />


    )
}

export default SalesByDate